<template>
  <div class="osk-layout-mini-header">
    <HeaderMini />

    <div class="osk-layout-mini-header__container">
      <slot />

      <Footer class="osk-layout-mini-header__footer" />
    </div>

    <ErrorDialog v-model="isErrorDialog" />

    <AuthPhoneVerifyDialog
      v-if="needPhoneVerify && isMounted"
      v-model="isPhoneVerifyDialog"
    />

    <ClientOnly>
      <notifications
        class="osk-notify-group osk-notify-group_center"
        group="mainNotifications"
        :duration="300000"
        classes="osk-notify"
        position="top center"
        width="auto"
        animation-name="slide-down-notify"
      />
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();
const url = useRequestURL();
const route = useRoute();
const { isErrorDialog } = useErrorDialog();
const { isMounted } = useUiBreakpoints();
const {
  needPhoneVerify,
  isPhoneVerifyDialog,
} = usePhoneVerification();

useHead({
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1',
    },
    {
      name: 'description',
      content: t('meta.description'),
    },
    {
      name: 'og:title',
      content: t('meta.title'),
    },
    {
      name: 'og:type',
      content: 'website',
    },
    {
      name: 'og:url',
      content: `${url.href + route.path}`,
    },
    {
      name: 'og:image',
      content: t('meta.image'),
    },
    {
      name: 'og:description',
      content: t('meta.description'),
    },
  ],
});

onMounted(() => {
  if (isPhoneVerifyDialog.value) {
    isPhoneVerifyDialog.value = false;
  }
});

</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-layout-mini-header {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__container {
    height: 100%;
  }
}
</style>
